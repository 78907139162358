.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo-animated {
    animation: App-logo-spin infinite 1s cubic-bezier(1, 0, 0, 1);
  }
}

.show-pointer {
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo2 {
  height: 200px;
  vertical-align: middle;
}

.printer-logo {
  height: 150px;
  transition: transform 1s;
  mix-blend-mode: multiply;
}

.printer-logo:hover {
  transform: scale(1.2);
}
